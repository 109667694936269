import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  width: 100%;
  max-width: 1059px;
`;

export const UList = styled.div``;

export const UListItem = styled.div`
  border-bottom: 1px solid rgba(33, 63, 78, 0.2);
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const Question = styled.div`
  font-family: P22 Underground;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #213f4e;
  cursor: pointer;
  position: relative;
  display: block;
  padding-right: 20px;
  &:after {
    content: ' ';
    position: absolute;
    right: 0;
    top: 0;
    display: inline-block;
    background-image: url('/images/fa-plus.svg');
    background-repeat: no-repeat;
    background-size: 15px;
    width: 15px;
    height: 17px;
  }

  &.active:after {
    background-image: url('/images/fa-minus.svg');
  }
  @media (max-width: 700px) {
    font-size: 20px;
  }
`;
export const Answer = styled.div`
  font-family: P22 Underground;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  color: #213f4e;

  display: none;
  &.active {
    display: block;
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.5s;
  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media (max-width: 700px) {
    font-size: 14px;
  }
`;
