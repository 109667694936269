import React, { useState, useContext } from 'react';
import * as S from './FaqsV2.styles';
import FunnelContext from '../../context/FunnelContext';

const Faqs = props => {
  const { questions } = props;
  const [activeIndex, setActiveIndex] = useState(-1);
  const { pageDesign } = useContext(FunnelContext);

  const FaqItem = ({ question, answer, index }) => {
    return (
      <S.UListItem>
        <S.Question
          className={`${activeIndex === index ? 'active' : ''} ${pageDesign}`}
          onClick={() => {
            if (activeIndex === index) {
              setActiveIndex(-1);
            } else {
              setActiveIndex(index);
            }
          }}
        >
          {question}
        </S.Question>
        <S.Answer
          className={activeIndex === index ? 'active' : ''}
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      </S.UListItem>
    );
  };

  return (
    <S.Container>
      <S.UList>
        {questions.map((item, index) => {
          return <FaqItem {...item} index={index} key={index} />;
        })}
      </S.UList>
    </S.Container>
  );
};

export default Faqs;
